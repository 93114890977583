import { graphql, useStaticQuery } from 'gatsby';

const useOurEventsQuery = () => {
  const data = useStaticQuery(graphql`
    query OurEvents {
      contentfulMainPage {
        content {
          title
          contentSlider {
            ... on ContentfulEventCard {
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.contentfulMainPage.content[2]
};

export default useOurEventsQuery;
