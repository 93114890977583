import React from "react";

import "./Arrow.scss";

const Arrow = ({ color, opacity, direction }) => {
  return (
    <svg className={`arrow ${direction === 'right' ? 'arrow--right' : ''}`} width="51" height="31" viewBox="0 0 51 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path d="M51 15.5002L-1.65403e-06 15.5003" stroke={color}/>
        <path d="M16.0844 31C16.0844 28.9645 15.6699 26.949 14.8647 25.0684C14.0594 23.1879 12.8791 21.4792 11.3912 20.0399C9.90334 18.6006 8.13694 17.4588 6.1929 16.6799C4.24886 15.9009 2.16525 15.5 0.0610338 15.5" stroke={color}/>
        <path d="M16.0844 -1.35505e-06C16.0844 2.03549 15.6699 4.05104 14.8647 5.93158C14.0594 7.81213 12.8791 9.52083 11.3912 10.9601C9.90334 12.3994 8.13694 13.5412 6.1929 14.3201C4.24886 15.0991 2.16525 15.5 0.0610366 15.5" stroke={color}/>
      </g>
    </svg>
  )
}

export default Arrow;
