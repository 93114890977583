import React, { useState } from "react";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';

import "./Headline.scss";

import useHeadlineQuery from '../../graphql/headline';
import Arrow from "../Arrow/Arrow";

const Star = ({ fill, className }) => (
  <svg className={className} width="165" height="164" viewBox="0 0 165 164" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M83.3955 37.9579L82.5 13.7669L81.6045 37.9579C80.7413 61.2772 62.053 79.9798 38.7605 80.8321L13.6722 81.7502L38.7605 82.6683C62.0529 83.5207 80.7413 102.223 81.6045 125.543L82.5 149.734L83.3955 125.543C84.2587 102.223 102.947 83.5207 126.24 82.6683L151.328 81.7502L126.24 80.8321C102.947 79.9798 84.2587 61.2772 83.3955 37.9579Z" stroke="white"/>
  </svg>
);

const Headline = () => {
  const data = useHeadlineQuery();
  const [swiper, setSwiper] = useState(null);
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);

  return (
    <section className="headline section-container">
      <div className="headline__content">
        <p className="headline__subtitle">{data?.contentfulHeadline.headlineOverview}</p>
        <h1 className="headline__title">{data?.contentfulHeadline.title}</h1>
        <div className="headline__description">
          <p>{data?.contentfulHeadline.subTitle}</p>
          <div className="mobile-stars">
            <Star className="mobile-stars--empty" fill="none" />
            <Star className="mobile-stars--fill" fill="#FFFFFF" />
          </div>
        </div>
        <div className="headline__cta">
          <Link to="/pay"><button className="cta can-help-action">{data?.contentfulHeadline.canHelpAction.title}</button></Link>
        </div>
      </div>
      <div className="headline__slider">
        <Swiper
          modules={[EffectFade]}
          effect="fade"
          speed={1000}
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={(slider) => {
            slider.activeIndex === 0
              ? setIsFirstSlide(true) : setIsFirstSlide(false)
            slider.activeIndex === slider.slides.length - 1
              ? setIsLastSlide(true) : setIsLastSlide(false)
          }}
          onSwiper={(swiper) => setSwiper(swiper)}
          className="slider"
        >
          {data?.contentfulHeadline.slider.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <img className="slider-image" src={item.file.url} alt="" />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <Star className="star-icon--empty" fill="none" />
        <Star className="star-icon--fill" fill="#FFFFFF" />
        <Star className="star-icon--small" fill="#FFFFFF" />
        <div className="slider-controls">
          <div
            className={`slider-controls__item-before ${isFirstSlide ? 'extreme-slide' : ''}`}
            onClick={() => swiper.slidePrev()}
          >
            <Arrow color="#FFFFFF" />
          </div>
          <div className="divider">/</div>
          <div
            className={`slider-controls__item-after ${isLastSlide ? 'extreme-slide' : ''}`}
            onClick={() => swiper.slideNext()}
          >
            <Arrow color="#FFFFFF" direction="right" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Headline;