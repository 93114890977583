import { graphql, useStaticQuery } from 'gatsby';

const useOurMissionQuery = () => {
  return useStaticQuery(graphql`
    query OurMission {
      contentfulMainPage {
        content {
          description {
            description
          }
          title
        }
      }
    }
  `);
};

export default useOurMissionQuery;
