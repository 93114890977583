import { graphql, useStaticQuery } from 'gatsby';

const useOurTeamQuery = () => {
  const data = useStaticQuery(graphql`
    query OurTeam {
      contentfulMainPage {
        content {
          title
          description {
            description
          }
          contentSlider {
            ... on ContentfulTeamCard {
              photo {
                file {
                  url
                }
              }
              name
              position
              description {
                description
              }
            }
          }
        }
      }
    }
  `);
  return data.contentfulMainPage.content[1]
};

export default useOurTeamQuery;
