import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import "./OurTeam.scss";

import useOurTeamQuery from '../../graphql/our-team';
import CrossfadeImage from '../CrossfadeImage/CrossfadeImage'
import Arrow from "../Arrow/Arrow";

const Star = ({ fill, className }) => (
  <svg className={className} width="154" height="153" viewBox="0 0 154 153" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M83.3955 37.9579L82.5 13.7669L81.6045 37.9579C80.7413 61.2772 62.053 79.9798 38.7605 80.8321L13.6722 81.7502L38.7605 82.6683C62.0529 83.5207 80.7413 102.223 81.6045 125.543L82.5 149.734L83.3955 125.543C84.2587 102.223 102.947 83.5207 126.24 82.6683L151.328 81.7502L126.24 80.8321C102.947 79.9798 84.2587 61.2772 83.3955 37.9579Z" stroke="white"/>
  </svg>
);

const OurTeam = () => {
  const data = useOurTeamQuery()

  const fadeDuration = 1000
  const [slides, setSlides] = useState()
  const [slideId, setSlideId] = useState(0)
  const [cardIsFlipped, setCardIsFlipped] = useState(data?.contentSlider.map(() => false))

  const flipCard = (event) => {
    setCardIsFlipped((state) => state.map((item, index) => {
        return index == event.target.id ? !item : false
      })
    )
  }

  const addLineBreaks = (text) => text.split('\n').reduce((children, textSegment, index) => {
    return [...children, index > 0 && <br key={index} />, textSegment];
  }, []);

  const splitIntoChunks = (array, size) => {
    const result = []
    array.forEach((item, index, array) => {
      const chunk = array.slice(index, index + size)
      const length = chunk.length
      if (length < size) {
        chunk.push(...array.slice(0, size - length))
      }
      result.push(chunk)
    })
    return result
  }

  const getCurrentSlide = () => {
    setSlides(splitIntoChunks(data?.contentSlider, 4)[slideId])
  }

  const setSlide = (id) => {
    setSlideId(id)
  }

  const setNextSlide = () => {
    const nextSlide = slideId < data?.contentSlider.length - 1 ? slideId + 1 : 0
    setSlideId(nextSlide)
  }

  const setPrevSlide = () => {
    const prevSlide = slideId - 1 < 0 ? data?.contentSlider.length - 1 : slideId - 1
    setSlideId(prevSlide)
  }

  useEffect(() => {
    getCurrentSlide()
  }, [slideId])

  return (
    <section id="our-team" className="our-team">
      <div className="our-team__desktop-section">
        <div className="our-team__desktop-slider">
          <div className="our-team__content-block">
            <h2 className="our-team__title">{data?.title}</h2>
            <p className="our-team__description">{addLineBreaks(data?.description.description)}</p>
          </div>

          <div className="empty-cards">
            <div className="empty-cards__item" />
            <div className="empty-cards__item" />
            <div className="empty-cards__item" />
            <div className="empty-cards__item" />
            <div className="empty-cards__item" />
          </div>

          {slides && (
            <div className="our-team-slider">
              <div className="our-team-slider__top-section">
                <Star className="our-team__star" fill="#FFFFFF" />
                <div className="our-team-slider__card our-team-slider__card--second flip-card">
                  <div className="flip-card--inner">
                    <div className="flip-card--front">
                      <div className="our-team-slider__photo">
                        <CrossfadeImage
                          src={slides[1].photo.file.url}
                          duration={fadeDuration}
                        />
                      </div>
                    </div>
                    <div className="flip-card--back">
                      <p>{slides[1].description.description}</p>
                    </div>
                  </div>
                  <div className="card-description bottom-left">
                    <h6 className="card-description__name">{slides[1].name}</h6>
                    <p className="card-description__position">{slides[1].position}</p>
                  </div>
                </div>
                <div className="our-team-slider__card our-team-slider__card--third flip-card">
                  <div className="flip-card--inner">
                    <div className="flip-card--front">
                      <div className="our-team-slider__photo">
                        <CrossfadeImage
                          src={slides[2].photo.file.url}
                          duration={fadeDuration}
                        />
                      </div>
                    </div>
                    <div className="flip-card--back">
                      <p>{slides[2].description.description}</p>
                    </div>
                  </div>
                  <div className="card-description bottom-right">
                    <h6 className="card-description__name">{slides[2].name}</h6>
                    <p className="card-description__position">{slides[2].position}</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="empty-card empty-card--first" />
                <div className="our-team-slider__card our-team-slider__card--first flip-card">
                  <div className="flip-card--inner">
                    <div className="flip-card--front">
                      <div className="our-team-slider__photo">
                        <CrossfadeImage
                          src={slides[0].photo.file.url}
                          duration={fadeDuration}
                        />
                      </div>
                    </div>
                    <div className="flip-card--back flip-card--back-reverse">
                      <p>{slides[0].description.description}</p>
                    </div>
                  </div>
                  <div className="card-description bottom-right">
                    <h6 className="card-description__name">{slides[0].name}</h6>
                    <p className="card-description__position">{slides[0].position}</p>
                  </div>
                </div>
                <div className="empty-card" />
                <div className="our-team-slider__card our-team-slider__card--last flip-card">
                  <div className="flip-card--inner">
                    <div className="flip-card--front">
                      <div className="our-team-slider__photo">
                        <CrossfadeImage
                          src={slides[3].photo.file.url}
                          duration={fadeDuration}
                        />
                      </div>
                    </div>
                    <div className="flip-card--back flip-card--back-reverse">
                      <p>{slides[3].description.description}</p>
                    </div>
                  </div>
                  <div className="card-description bottom-left">
                    <h6 className="card-description__name">{slides[3].name}</h6>
                    <p className="card-description__position">{slides[3].position}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="empty-cards empty-cards--reversed">
            <div className="empty-cards__item" />
            <div className="empty-cards__item" />
            <div className="empty-cards__item" />
            <div className="empty-cards__item" />
            <div className="empty-cards__item" />
          </div>
        </div>

        <div className="our-team__slider-controls">
          <div
            className="our-team__slider-controls--before"
            onClick={() => setPrevSlide()}
          >
            <Arrow color="#FFFFFF" />
          </div>
          <div className="our-team__slider-indexes">
            {data?.contentSlider.map((item, index) =>
              <div key={index}>
                {slideId === index &&
                  <div className="our-team__slide-index--active">
                    {`0${index + 1}`}
                  </div>
                }
                {slideId !== index &&
                  <div
                    className="our-team__slide-index"
                    onClick={() => setSlide(index)}
                  >
                    {`0${index + 1}`}
                  </div>
                }
              </div>
            )}
          </div>
          <div
            className="our-team__slider-controls--after"
            onClick={() => setNextSlide()}
          >
            <Arrow color="#FFFFFF" direction="right" />
          </div>
        </div>
      </div>
      
      <div className="our-team__mobile-section">
        <div className="our-team__mobile-content">
          <h2 className="our-team__mobile-title">{data?.title}</h2>
          <p className="our-team__mobile-description">{addLineBreaks(data?.description.description)}</p>
        </div>
        <Swiper
          spaceBetween={24}
          slidesPerView={"auto"}
          className="our-team__mobile-slider"
        >
          {data?.contentSlider.map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                className="our-team__mobile-slider-slide"
                onClick={(event) => flipCard(event)}
              >
                <div className="flip-card our-team__mobile-slider-image">
                  <div className={`flip-card--inner ${cardIsFlipped[index] ? 'active' : ''}`}>
                    <div className="flip-card--front">
                      <img id={index} className="our-team__mobile-slider-image" src={item.photo.file.url} alt="" />
                    </div>
                    <div className="flip-card--back">
                      <p id={index}>{item.description.description}</p>
                    </div>
                  </div>
                </div>
                <div className="description">
                  <h6 className="description__name">{item.name}</h6>
                  <p className="description__position">{item.position}</p>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </section>
  )
}

export default OurTeam;
