import React, { useState } from "react";
import Collapse from "@kunukn/react-collapse";

import "./OurMission.scss";

import useOurMissionQuery from '../../graphql/our-mission';
import CoatOfArms from "../../images/coat-of-arms.svg";

const OurMission = () => {
  const data = useOurMissionQuery();
  const [isExpanded, setIsExpanded] = useState(false);

  const addLineBreaks = (text) => text.split('\n').reduce((children, textSegment, index) => {
    return [...children, index > 0 && <br key={index} />, textSegment];
  }, []);

  return (
    <section id="our-mission" className="our-mission section-container">
      <div className="our-mission__title">
        <h2>{data?.contentfulMainPage.content[0].title}</h2>
      </div>
      <div className="our-mission__content-wrapper">
        <div className="our-mission__content">
          <Collapse
            isOpen={isExpanded}
            collapseHeight="100px"
            className="our-mission__collapse"
            render={() => (
              <div
                className="our-mission__description"
              >
                {addLineBreaks(data?.contentfulMainPage.content[0].description.description)}
              </div>
            )}
          />
          <div className="our-mission__link-wrapper">
            <a
              className="our-mission__link"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? 'hide' : 'learn more'}
            </a>
          </div>
        </div>
      </div>
      <img className="our-mission__background" src={CoatOfArms} alt="coat-of-arms" />
    </section>
  )
}

export default OurMission;
