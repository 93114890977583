import * as React from "react";
import { Helmet } from "react-helmet"

import "../styles.scss";

import Header from "../components/Header/Header";
import Headline from "../components/Headline/Headline";
import OurMission from "../components/OurMission/OurMission";
import OurEvents from "../components/OurEvents/OurEvents";
import Footer from "../components/Footer/Footer";
import OurTeam from "../components/OurTeam/OurTeam";
import useSiteQuery from "../graphql/index";

const HomePage = () => {
  const data = useSiteQuery();

  return (
    <>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
        <meta name="og:title" content={data.site.siteMetadata.title} />
        <meta name="og:type" content="website" />
        <meta name="og:description"  content={data.site.siteMetadata.description} />
        <meta name="og:image" content="" />
        <meta name="og:locale" content="en_US" />
        <meta name="og:url" content={data.site.siteMetadata.siteUrl} />
        <link rel="canonical" href={data.site.siteMetadata.siteUrl} />
      </Helmet>
      <main className="container">
        <Header />
        <Headline />
        <OurMission />
        <OurTeam />
        <OurEvents />
        <Footer />
      </main>
    </>
  )
}

export default HomePage;
