import { graphql, useStaticQuery } from 'gatsby';

const useHeadlineQuery = () => {
  return useStaticQuery(graphql`
    query Headline {
      contentfulHeadline {
        title
        subTitle
        headlineOverview
        needHelpAction {
          title
          url
        }
        canHelpAction {
          title
          url
        }
        slider {
          file {
            url
          }
        }
      }
    }
  `);
};

export default useHeadlineQuery;