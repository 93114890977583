import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import "./OurEvents.scss";

import useOurEventsQuery from '../../graphql/our-events';
import Arrow from "../Arrow/Arrow";

const OurEvents = () => {
  const data = useOurEventsQuery();
  const [swiper, setSwiper] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [])

  return (
    <section id="our-events" className="our-events section-container">
      <div className="our-events__slider">
        <h2 className="our-events__title">{data?.title}</h2>
        <Swiper
          spaceBetween={24}
          slidesPerView={"auto"}
          onSlideChange={(slider) => {
            setActiveSlideIndex(slider.realIndex)
          }}
          onSwiper={(swiper) => setSwiper(swiper)}
        >
          {data?.contentSlider.map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                className="our-events__slide"
              >
                <img className="our-events__slider-image" src={item.image.file.url} alt="" />
              </SwiperSlide>
            )
          })}
        </Swiper>
        {data?.contentSlider.length > 3 &&
          <div className="our-events__slider-controls">
            <div
              className="our-events__slider-controls-before"
              onClick={() => swiper.slidePrev()}
            >
              <Arrow color="#FFFFFF" />
            </div>
            <div className="our-events__slider-indexes">
              {data?.contentSlider.map((item, index) =>
                <div key={index}>
                  {activeSlideIndex === index &&
                    <div className="slide-index--active">
                      {`0${index + 1}`}
                    </div>
                  }
                  {activeSlideIndex !== index &&
                    <div
                      className="slide-index"
                      onClick={() => swiper?.slideToLoop(index)}
                    >
                      {`0${index + 1}`}
                    </div>
                  }
                </div>
              )}
            </div>
            <div
              className="our-events__slider-controls-after"
              onClick={() => swiper.slideNext()}
            >
              <Arrow color="#FFFFFF" direction="right" />
            </div>
          </div>
        }
      </div>
    </section>
  )
}

export default OurEvents;
